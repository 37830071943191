import React from "react";
import {
  BrowserRouter,
  Routes,
  Route
} from "react-router-dom";
import { Header } from "./Header";
import { About } from "./About";
import { Pricing } from "./Pricing";
import { Gutter } from "../../styles/components";
import { ThemeProvider } from '@mui/material/styles';
import { mainTheme } from "../../styles/themes";
import { CssBaseline } from "@mui/material";

export const Splash = () => {
  const data = document.getElementById("splash")?.dataset;
  const demoAccessToken = data?.demoAccessToken as string;
  delete data?.demoAccessToken;

  return (
    <BrowserRouter>
      <ThemeProvider theme={mainTheme}>
        <CssBaseline />
        <Header />
        <Gutter>
          <Routes>
            <Route path="/" element={<About demoAccessToken={demoAccessToken} />}/>
            <Route path="/pricing" element={<Pricing />}/>
          </Routes>
        </Gutter>
      </ThemeProvider>
    </BrowserRouter>
  )
}