import { createTheme } from '@mui/material/styles';

const mainTheme = createTheme({
  palette: {
    mode: "dark"
  }
});

mainTheme.typography.h1 = {
  [mainTheme.breakpoints.down('md')]: {
    fontSize: '2.5rem',
  },
  [mainTheme.breakpoints.up('md')]: {
    fontSize: '3.5rem',
  },
}

mainTheme.typography.h2 = {
  [mainTheme.breakpoints.down('md')]: {
    fontSize: '2rem',
  },
  [mainTheme.breakpoints.up('md')]: {
    fontSize: '3rem',
  },
}

export { mainTheme };