import { Button, Typography } from "@mui/material";
import React from "react";
import { SpacerBox, SplashPageContainer } from "../../styles/components";

export const Pricing = () => {
  return <SplashPageContainer>
    <Typography variant="h1" textAlign="center">Pricing</Typography>
    <Typography variant="h2" textAlign="center">Pay As You Go</Typography>
    <SpacerBox>
      <Typography variant="h4">Pay for Usage, not Monthly</Typography>
      <Typography>We are all tired of monthly subscriptions. Coupon API allows you to pay by usage.</Typography>
    </SpacerBox>
    <SpacerBox>
      <Typography variant="h4">Currently in Alpha Testing</Typography>
      <Typography>We are releasing Coupon API for Alpha testing in Q3 of 2024. Alpha testers will be given deeply discounted rates as we work toward launching the full product. Please reach out if you are interested in the product and would like information about joining the Alpha test.</Typography>
    </SpacerBox>
      <SpacerBox>
        <Button
          rel="noopener noreferrer"
          href="mailto:hello.coupon.api@gmail.com"
        >
          <Typography variant="button">
              Contact Us at hello.coupon.api@gmail.com
          </Typography>
        </Button>
      </SpacerBox>
  </SplashPageContainer>
}