import React, { useContext } from "react";
import { useNavigate } from "react-router-dom";
import {
  AppBar,
  Box,
  Divider,
  Drawer,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  Menu,
  MenuItem,
  Toolbar,
  Tooltip,
  Typography
} from "@mui/material";
import MenuIcon from '@mui/icons-material/Menu';
import { AccountCircle } from '@mui/icons-material';
import { deleteUserSession } from "../../helpers/requests";
import { signInPath } from "../../helpers/paths";
import { Gutter } from "../../styles/components";
import { CurrentUserContext } from "../../helpers/currentUserContext";

export const Header = () => {
  const navigate = useNavigate();
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [mobileOpen, setMobileOpen] = React.useState<boolean>(false);
  const { admin } = useContext(CurrentUserContext);
  const drawerWidth = 240;

  const handleDrawerToggle = () => {
    setMobileOpen((prevState) => !prevState);
  };

  const handleOpenMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  const signOut = () => {
    deleteUserSession().then(() => {
      location.href = signInPath;
    });
  };

  const drawer = (
    <Box onClick={handleDrawerToggle} sx={{ textAlign: 'center' }}>
      <Typography variant="h6" sx={{ my: 2 }}>
        Coupon API
      </Typography>
      <Divider />
      <List>  
        <ListItem disablePadding>
          <ListItemButton
            sx={{ textAlign: 'center' }}
            onClick={() => navigate("/campaigns")}
          >
            <ListItemText primary="Campaigns" />
          </ListItemButton>
        </ListItem>
        <ListItem disablePadding>
          <ListItemButton
            sx={{ textAlign: 'center' }}
            onClick={() => navigate("/coupons")}
          >
            <ListItemText primary="Coupons" />
          </ListItemButton>
        </ListItem>
        <ListItem disablePadding>
          <ListItemButton
            sx={{ textAlign: 'center' }}
            onClick={() => navigate("/campaigns/new")}
          >
            <ListItemText primary="New Campaign" />
          </ListItemButton>
        </ListItem>
        <ListItem disablePadding>
          <ListItemButton
            sx={{ textAlign: 'center' }}
            onClick={() => navigate("/organization")}
          >
            <ListItemText primary="Settings" />
          </ListItemButton>
        </ListItem>
        {admin && (<ListItem disablePadding>
          <ListItemButton
            sx={{ textAlign: 'center' }}
            onClick={() => navigate("/tools")}
          >
            <ListItemText primary="Tools" />
          </ListItemButton>
        </ListItem>)}
        <ListItem disablePadding>
          <ListItemButton
            sx={{ textAlign: 'center' }}
            onClick={signOut}
          >
            <ListItemText primary="Log Out" />
          </ListItemButton>
        </ListItem>
      </List>
    </Box>
  );

  return <Box>
    <AppBar position="static">
      <Gutter>
        <Toolbar variant="dense" disableGutters>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            edge="start"
            onClick={handleDrawerToggle}
            sx={{ display: { xs: "flex", sm: 'none' } }}
          >
            <MenuIcon />
          </IconButton>
          <Box display="flex" flexGrow={1} sx={{ display: { xs: 'none', sm: 'flex' } }}>
            <MenuItem onClick={() => navigate("/campaigns")}>
              <Typography textAlign="center">Campaigns</Typography>
            </MenuItem>
            <MenuItem onClick={() => navigate("/coupons")}>
              <Typography textAlign="center">Coupons</Typography>
            </MenuItem> 
            <MenuItem onClick={() => navigate("/campaigns/new")}>
              <Typography textAlign="center">New Campaign</Typography>
            </MenuItem>
          </Box>
          <Box sx={{ display: { xs: 'none', sm: 'flex' } }}>
            <Tooltip title="Open settings">
              <IconButton onClick={handleOpenMenu}
                size="large"
                aria-label="account of current user"
                aria-controls="primary-search-account-menu"
                aria-haspopup="true"
                color="inherit"
              >
                <AccountCircle />
              </IconButton>
            </Tooltip>
            <Menu
              sx={{ mt: '45px' }}
              id="menu-appbar"
              anchorEl={anchorEl}
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              keepMounted
              transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              open={Boolean(anchorEl)}
              onClose={handleCloseMenu}
            >
              <MenuItem onClick={() => navigate("/organization")}>
                <Typography textAlign="center">Settings</Typography>
              </MenuItem>
              {admin && (
                <MenuItem onClick={() => navigate("/tools")}>
                  <Typography textAlign="center">Tools</Typography>
                </MenuItem>
              )}
              <MenuItem onClick={signOut}>
                <Typography textAlign="center">Log Out</Typography>
              </MenuItem>
            </Menu>
          </Box>
        </Toolbar>
      </Gutter>
    </AppBar>
    <nav>
      <Drawer
        container={document.body}
        variant="temporary"
        open={mobileOpen}
        onClose={handleDrawerToggle}
        ModalProps={{
          keepMounted: true, // Better open performance on mobile.
        }}
        sx={{
          display: { xs: 'block', sm: 'none' },
          '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
        }}
      >
        {drawer}
      </Drawer>
    </nav>
  </Box>
}