import React, { useState, useEffect } from "react";
import { fetchCampaign, fetchCampaignCoupons } from "../../helpers/requests";
import { useParams } from "react-router-dom";
import { Campaign as CampaignType, Coupon } from "../../helpers/types";
import { CampaignValue } from "./CampaignValue";
import { CouponsTable } from "./CouponsTable";

export const Campaign = () => {
  const { campaignId } = useParams();

  const [campaignData, setCampaignData] = useState<CampaignType>();
  const [coupons, setCoupons] = useState<Coupon[]>();

  const getCampaignData = async () => {
    if (campaignId) {
      return await fetchCampaign(campaignId);
    }
  }

  const getCampaignCoupons = async () => {
    if (campaignId) {
      return await fetchCampaignCoupons(campaignId);
    }
  }

 useEffect(() => {
    getCampaignData().then(setCampaignData);
    getCampaignCoupons().then(setCoupons);
  }, []);

  return <div>
    <h2>Campaign {campaignId}</h2>
    {campaignData && (
      <div>
        <div>
          <b>Name:</b> <span>{campaignData.name}</span>
        </div>
        <div>
          <b>Type:</b> <span>{campaignData.campaign_type}</span>
        </div>
        <div>
          <b>Value:</b> <span><CampaignValue campaign={campaignData} /></span>
        </div>
        {coupons && <CouponsTable coupons={coupons} />}
      </div>
    )}
  </div>
}