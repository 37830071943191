import React, { useState, useEffect } from "react";
import { fetchCoupons } from "../../helpers/requests";
import { Coupon } from "../../helpers/types";
import { CouponsTable } from "./CouponsTable";

export const Coupons = () => {
  const [coupons, setCoupons] = useState<Coupon[]>();

  const getCoupons = async () => await fetchCoupons();

 useEffect(() => {
    getCoupons().then(setCoupons)
  }, [])

  return <div>
    <h2>Coupons</h2>
    {coupons && <CouponsTable coupons={coupons} />}
  </div>
}