import React from "react";
import { useNavigate } from "react-router-dom";
import { AppBar, Box, Divider, Drawer, IconButton, List, ListItem, ListItemButton, ListItemText, MenuItem, Toolbar, Typography } from "@mui/material";
import { apiDocsPath, signInPath } from "../../helpers/paths";
import { Gutter } from "../../styles/components";
import MenuIcon from '@mui/icons-material/Menu';

export const Header = () => {
  const navigate = useNavigate();
  const [mobileOpen, setMobileOpen] = React.useState<boolean>(false);
  const drawerWidth = 240;

  const handleDrawerToggle = () => {
    setMobileOpen((prevState) => !prevState);
  };

  const drawer = (
    <Box onClick={handleDrawerToggle} sx={{ textAlign: 'center' }}>
      <Typography variant="h6" sx={{ my: 2 }}>
        Coupon API
      </Typography>
      <Divider />
      <List>
        <ListItem disablePadding>
          <ListItemButton
            sx={{ textAlign: 'center' }}
            onClick={() => navigate("/")}
          >
            <ListItemText primary="Home" />
          </ListItemButton>
        </ListItem>
        <ListItem disablePadding>
          <ListItemButton
            sx={{ textAlign: 'center' }}
            onClick={() => location.href = apiDocsPath}
          >
            <ListItemText primary="API Docs" />
          </ListItemButton>
        </ListItem>
        <ListItem disablePadding>
          <ListItemButton
            sx={{ textAlign: 'center' }}
            onClick={() => navigate("/pricing")}
          >
            <ListItemText primary="Pricing" />
          </ListItemButton>
        </ListItem>
        <ListItem disablePadding>
          <ListItemButton
            sx={{ textAlign: 'center' }}
            onClick={() =>location.href = signInPath}
          >
            <ListItemText primary="Log In" />
          </ListItemButton>
        </ListItem>
      </List>
    </Box>
  );

  return <Box>
    <AppBar position="static">
      <Gutter>
        <Toolbar variant="dense" disableGutters>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            edge="start"
            onClick={handleDrawerToggle}
            sx={{ display: { xs: "flex", sm: 'none' } }}
          >
            <MenuIcon />
          </IconButton>
          <Box display="flex" flexGrow={1} sx={{ display: { xs: 'none', sm: 'flex' } }}>
            <MenuItem onClick={() => navigate("/")}>
              <Typography textAlign="center">Coupon API</Typography>
            </MenuItem>
            <MenuItem onClick={() => location.href = apiDocsPath}>
              <Typography textAlign="center">API Docs</Typography>
            </MenuItem>
            <MenuItem onClick={() => navigate("/pricing")}>
              <Typography textAlign="center">Pricing</Typography>
            </MenuItem>
          </Box>
          <Box sx={{ display: { xs: 'none', sm: 'flex' } }}>
            <MenuItem onClick={() =>location.href = signInPath}>
              <Typography textAlign="center">Log In</Typography>
            </MenuItem>
          </Box>
        </Toolbar>
      </Gutter>
    </AppBar>
    <nav>
      <Drawer
        container={document.body}
        variant="temporary"
        open={mobileOpen}
        onClose={handleDrawerToggle}
        ModalProps={{
          keepMounted: true, // Better open performance on mobile.
        }}
        sx={{
          display: { xs: 'block', sm: 'none' },
          '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
        }}
      >
        {drawer}
      </Drawer>
    </nav>
  </Box>
}