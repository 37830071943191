import React, { FC, useEffect, useState } from "react";
import { createAccessToken, fetchAccessToken } from "../../helpers/requests";
import { AccessToken as AccessTokenType } from "../../helpers/types";
import { Button, TextField } from "@mui/material";

export const AccessToken: FC = () => {
  const [accessTokenData, setAccessTokenData] = useState<AccessTokenType>();

  const getAccessTokenData = async () => {
    return await fetchAccessToken();
  }

  const generateAccessToken = async () => {
    const confirmation = confirm("Are you sure? This will invalidate any existing access tokens.")
    
    if (!confirmation) return;

    return await createAccessToken().then(accessToken => {
      location.reload();
    });
  }

  useEffect(() => {
    getAccessTokenData().then(accessToken => {
      setAccessTokenData(accessToken);
    });
  }, [])

  return <div>
    <h2>Access Token</h2>
    {accessTokenData &&
      <TextField
        id="outlined-read-only-input"
        label="Read Only"
        value={accessTokenData.value}
        InputProps={{
          readOnly: true,
        }}
        fullWidth
      />}

    <Button onClick={generateAccessToken}>
      Regenerate Access Token
    </Button>
  </div>
}