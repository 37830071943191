export const campaignsPath = "/api/campaigns";
export const campaignPath = (campaignId: string) => `/api/campaigns/${campaignId}`;
export const couponsPath = "/api/coupons";
export const validateCouponPath = (code) => `/api/validate?code=${code}`;
export const redeemCouponPath = (code) => `/api/redeem?code=${code}`;
export const couponPath = (couponId: string | number) => `/api/coupons/${couponId}`;
export const campaignCouponsPath = (campaignId: string) =>`/api/campaigns/${campaignId}/coupons`;
export const generateCampaignCouponsPath = (campaignId: string, numCoupons: number, code?: string) =>`/api/generate?number=${numCoupons}&campaign_id=${campaignId}${code ? ("&code=" + code) : ''}`;
export const organizationPath = "/api/get_organization";
export const signOutPath = "/users/sign_out";
export const signInPath = "/users/sign_in";
export const organizationAccessTokensPath = "/api/access_tokens";

export const apiDocsPath = "https://documenter.getpostman.com/view/2439319/2sA3rwNZo2";