import React from "react";
import { Coupons } from "./Coupons";
import { Campaigns } from "./Campaigns";
import { Campaign } from "./Campaign";
import { CampaignCoupons } from "./CampaignCoupons";
import { NewCampaign } from "./NewCampaign";
import { OrganizationSettings } from "./OrganizationSettings";
import {
  BrowserRouter,
  Routes,
  Route
} from "react-router-dom";
import { Header } from "./Header";
import { Gutter } from "../../styles/components";
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { Tools } from "./Tools";
import { CurrentUserContext } from "../../helpers/currentUserContext";
import { ThemeProvider } from '@mui/material/styles';
import { mainTheme } from "../../styles/themes";
import { CssBaseline } from "@mui/material";

export const App = () => {
  const data = document.getElementById("app")?.dataset;
  const isCurrentUserAdmin = data?.adminUser === "true";
  delete data?.adminUser;

  return (
    <BrowserRouter>
      <CurrentUserContext.Provider value={{ admin: isCurrentUserAdmin }}>
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <ThemeProvider theme={mainTheme}>
            <CssBaseline />
            <Header />
            <Gutter>
              <Routes>
                <Route path="/" element={<OrganizationSettings />}/>
                <Route path="/campaigns" element={<Campaigns />}/>
                <Route path="/campaigns/:campaignId" element={<Campaign />}/>
                <Route path="/campaigns/:campaignId/coupons" element={<CampaignCoupons />}/>
                <Route path="/campaigns/new" element={<NewCampaign />} />
                <Route path="/coupons" element={<Coupons />}/>
                <Route path="/organization" element={<OrganizationSettings />}/>
                <Route path="/tools" element={<Tools />}/>
              </Routes>
            </Gutter>
            </ThemeProvider>
          </LocalizationProvider>
        </CurrentUserContext.Provider>
    </BrowserRouter>
  )
}