import React, { FC } from "react";
import { Link } from "react-router-dom";
import { Coupon } from "../../helpers/types";
import { Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from "@mui/material";
import { Delete as DeleteIcon } from '@mui/icons-material';
import { destroy } from "../../helpers/requests";
import { couponPath } from "../../helpers/paths";

export const CouponsTable: FC<{ coupons: Coupon[] }> = ({ coupons }) => {
  const handleDelete = async (couponId: number) => {
    const confirmation = confirm("Are you sure?");
    
    if(confirmation) {
      await destroy(couponPath(couponId));
      location.reload();
    }
  };

  return <TableContainer component={Paper}>
  <Table sx={{ minWidth: 650 }}>
    <TableHead>
      <TableRow>
        <TableCell align="right">ID</TableCell>
        <TableCell align="right">Value</TableCell>
        <TableCell align="right">Campaign</TableCell>
        <TableCell align="right">Actions</TableCell>
      </TableRow>
    </TableHead>
    <TableBody>
    {coupons.map(coupon =>
      <TableRow key={coupon.id}>
        <TableCell align="right">{coupon.id}</TableCell>
        <TableCell align="right">{coupon.code}</TableCell>
        <TableCell align="right">
          <Link to={`/campaigns/${coupon.campaign_id}`}>Campaign</Link>
        </TableCell>
        <TableCell align="right">
          <DeleteIcon style={{ cursor: "pointer "}} onClick={() => handleDelete(coupon.id)} />
        </TableCell>
      </TableRow>)}
    { (coupons.length === 0) &&
      <TableRow>
        <TableCell component="th" scope="row">
          No Coupons exist
        </TableCell>
      </TableRow>
    }
    </TableBody>
  </Table>
</TableContainer>}
