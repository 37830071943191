import React, { useState, useEffect } from "react";
import { fetchOrganization } from "../../helpers/requests";
import { Organization } from "../../helpers/types";
import { AccessToken } from "./AccessToken";

export const OrganizationSettings = () => {
  const [organizationData, setOrganizationData] = useState<Organization>();

  const getData = async () => {
    return await fetchOrganization();
  }

 useEffect(() => {
    getData().then(setOrganizationData)
  }, []);

  return <div>
    {organizationData && (
      <div>
        <h2>{organizationData.name}</h2>
        <AccessToken />
      </div>
    )}
  </div>
}