import React, { useState, useEffect, ChangeEvent } from "react";
import { fetchCampaignCoupons, generateCoupons, get } from "../../helpers/requests";
import { Link, useParams } from "react-router-dom";
import { Coupon } from "../../helpers/types";
import { useForm } from "react-hook-form";
import { Button, Checkbox, FormControlLabel, TextField } from "@mui/material";

export const CampaignCoupons = () => {
  const [displayCode, setDisplayCode] = useState<boolean>(false);
  const { register, handleSubmit, formState: { errors } } = useForm();
  const { campaignId } = useParams();

  const [couponsData, setCouponsData] = useState<Coupon[]>();

  const getCouponsData = async () => {
    if (campaignId) {
      return await fetchCampaignCoupons(campaignId);
    }
  }
  
  const handleGenerateCoupons = async (data) => {
    if (campaignId) {
      await generateCoupons(campaignId, data.numCoupons, displayCode ? data.code : null);
      location.reload();
    }
  }

 useEffect(() => {
    getCouponsData().then(setCouponsData)
  }, []);

  const handleDisplayCodeCheckboxChange = (_event: ChangeEvent<HTMLInputElement>, checked: boolean) => {
    setDisplayCode(checked);
  }

  return <div>
    <h2>Coupons for Campaign {campaignId}</h2>
    <Link to={`/campaigns/${campaignId}`}>Back to Campaign</Link>
    <h3>Create Coupons</h3>
    <form onSubmit={handleSubmit(handleGenerateCoupons)}>
      <div>
      <FormControlLabel
          control={
            <TextField type="number" error={Boolean(errors.numCoupons)} {...register("numCoupons", {
              required: true,
              validate: {
                min: (v: number) => v > 0,
                max: (v: number) => v < 500
              }
            })} />
          }
          label="How many?"
          labelPlacement="end"
        />
        
        {errors.numCoupons && errors.numCoupons.type === "required" && <div>Number of codes is required</div>}
        {errors.numCoupons && errors.numCoupons.type === "max" && <div>Please use a number smaller than 500</div>}
        {errors.numCoupons && errors.numCoupons.type === "min" && <div>Please use a number larger than 0</div>}
      </div>
      <div>
        <FormControlLabel
          value="end"
          control={
            <Checkbox
              checked={displayCode}
              onChange={handleDisplayCodeCheckboxChange}
              inputProps={{ 'aria-label': 'controlled' }}
            />
          }
          label="Use predefined Code? (keep unchecked to create random codes)"
          labelPlacement="end"
        />
      </div>
      {displayCode && (
        <div>
          <TextField
            {...register("code", { required: displayCode })}
            error={Boolean(errors.code)}
            label="Code"
            type="text"
            variant="outlined"
          />
          {errors.code && <div>Code is required</div>}
        </div>
      )}
      <div>
        <Button type="submit" variant="outlined">Create Coupons</Button>
      </div>
    </form>
    <hr />
    <h2>Coupons for Campaign:</h2>
    {couponsData && couponsData.map(campaignCoupon => <div key={campaignCoupon.id}>
      Coupon: {campaignCoupon.code}
    </div>)}
  </div>
}