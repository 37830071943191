  import React, { ChangeEvent, useState } from "react";
import { post } from "../../helpers/requests";
import { useNavigate } from "react-router-dom";
import { campaignsPath } from "../../helpers/paths";
import { Box, Button, Checkbox, FormControlLabel, TextField, ToggleButton, ToggleButtonGroup } from "@mui/material";
import styled from '@emotion/styled'
import { useForm } from "react-hook-form";
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import dayjs, { Dayjs } from "dayjs";

const Form = styled.form`
  align-items: center;
  display: flex;
  flex-direction: column;
`

const ValueContainer = styled.div`
  align-items: center;
  display: flex;
  position: relative;
`

const FixedContainer = styled.span`
  position: absolute;
  font-size: 3rem;
  left: -30px;
`

const PercentContainer = styled.span`
  position: absolute;
  font-size: 3rem;
  right: -40px;
`

const FieldContainer = styled.div`
  width: 100%;
  padding: 12px 0px;
`

export const NewCampaign = () => {
  const { register, setValue, handleSubmit, formState: { errors } } = useForm();
  const navigate = useNavigate();
  const [displayTimeOptions, setDisplayTimeOptions] = useState<boolean>(false);
  const [displayCode, setDisplayCode] = useState<boolean>(false);
  const [campaignType, setCampaignType] = useState<string>('fixed');
  const [startsAt, setStartsAt] = React.useState<Dayjs | null>(dayjs());
  const [endsAt, setEndsAt] = React.useState<Dayjs | null>(null);

  const createCampaign = async (data) => {
    const startsAndEndsAt = displayTimeOptions ? {
      starts_at: dayjs(data.starts_at).toISOString(),
      ends_at: data.ends_at && dayjs(data.ends_at).toISOString()
    } : {};

    const code = displayCode ? { code: data.code } : {}

    const payload = {
      campaign: {
        name: data.name,
        value: data.value,
        campaign_type: campaignType,
        ...startsAndEndsAt,
        ...code
      }
    };

    const result = await post(campaignsPath, payload);

    if (result.success) {
      navigate("/campaigns");
    } else {
      alert("something went wrong");
    }
  }

  const handleChange = (
    _event: React.MouseEvent<HTMLElement>,
    newCampaignType: string,
  ) => {
    setCampaignType(newCampaignType);
  }

  const handleDisplayTimeCheckboxChange = (_event: ChangeEvent<HTMLInputElement>, checked: boolean) => {
    setDisplayTimeOptions(checked);
  }

  const handleDisplayCodeCheckboxChange = (_event: ChangeEvent<HTMLInputElement>, checked: boolean) => {
    setDisplayCode(checked);
  }

  const valueValidation = campaignType == "percent" ? { min: 1, max: 100 } : {};

  return <div>
    <h2>Create Campaign</h2>
    <Box margin="auto" sx={{ width: { xs: '100%', sm: '500px' } }}>
      <Form onSubmit={handleSubmit(createCampaign)} style={{ width: "100%" }}>
        <FieldContainer>
          <ToggleButtonGroup
            color="primary"
            value={campaignType}
            exclusive
            onChange={handleChange}
            style={{ display: "flex", justifyContent: "center" }}
          >
            <ToggleButton value="fixed">Fixed</ToggleButton>
            <ToggleButton value="percent">Percent</ToggleButton>
          </ToggleButtonGroup>
        </FieldContainer>
        <FieldContainer>
          <TextField
            {...register("name", { required: true })}
            error={Boolean(errors.name)}
            label="Name"
            type="text"
            variant="outlined"
            fullWidth
          />
        </FieldContainer>
        {errors.name && errors.name.type === "required" && <div>Name is required to create a campaign</div>}
        <FieldContainer>
          <ValueContainer>
            {campaignType == "fixed" && <FixedContainer>$</FixedContainer>}
            <TextField
              {...register("value", { required: true, ...valueValidation })}
              error={Boolean(errors.value)}
              label="Value"
              type="number"
              variant="outlined"
              fullWidth
            />
            {campaignType == "percent" && <PercentContainer>%</PercentContainer>}
          </ValueContainer>
        </FieldContainer>
        {errors.value && errors.value.type === "max" && <div>Value should be between 1 and 100 for Percent campaigns</div>}
        {errors.value && errors.value.type === "required" && <div>Value is required to create a campaign</div>}
        <FormControlLabel
          value="end"
          control={
            <Checkbox
              checked={displayTimeOptions}
              onChange={handleDisplayTimeCheckboxChange}
              inputProps={{ 'aria-label': 'controlled' }}
            />
          }
          label="Time Window?"
          labelPlacement="end"
        />
        {displayTimeOptions && (
          <div style={{ display: "flex" }}>
            <FormControlLabel
              value="top"
              control={<DateTimePicker
                {...register("starts_at", { required: displayTimeOptions })}
                value={startsAt}
                onChange={(newValue) => {
                  setStartsAt(newValue);
                  setValue('starts_at', newValue, { shouldValidate: true });
                }}
              />}
              label="Start"
              labelPlacement="top"
            />
            <FormControlLabel
              value="top"
              control={<DateTimePicker
                {...register("ends_at", {
                  validate: {
                    afterStartsAt: (v: Dayjs) => v ? v.isAfter(startsAt) : true
                  }
                })}
                value={endsAt}
                onChange={(newValue) => {
                  setEndsAt(newValue);
                  setValue('ends_at', newValue, { shouldValidate: true });
                }}
              />}
              label="End"
              labelPlacement="top"
            />
            {errors.starts_at && <div>Start Date required (uncheck "Time Window?" for open-ended campaigns) </div>}
            {errors.ends_at && <div>End Date must come after Start Date</div>}
          </div>
        )}
        <FormControlLabel
          value="end"
          control={
            <Checkbox
              checked={displayCode}
              onChange={handleDisplayCodeCheckboxChange}
              inputProps={{ 'aria-label': 'controlled' }}
            />
          }
          label="Unlimited uses?"
          labelPlacement="end"
        />
        {displayCode && (
          <div>
            <FieldContainer>
              <TextField
                {...register("code", { required: displayCode })}
                error={Boolean(errors.code)}
                label="Code"
                type="text"
                variant="outlined"
                fullWidth
              />
            </FieldContainer>
            {errors.code && <div>Code is required</div>}
          </div>
        )}
        <FieldContainer style={{ display: "flex", justifyContent: "center" }}>
          <Button type="submit" variant="outlined">Create Campaign</Button>
        </FieldContainer> 
      </Form>
    </Box>
  </div>
}