import styled from "@emotion/styled";

const gutterMargin = 96;
const mobileGutterMargin = 24;
const splashTopMargin = 24;
const breakpoint = 600;

export const Gutter = styled.div`
  margin: 0px ${gutterMargin}px;

  @media screen and (max-width: ${breakpoint}px) {
    margin: 0px ${mobileGutterMargin}px;
  }
`

export const SplashPageContainer = styled.div`
  margin: ${splashTopMargin}px 0px;
`

export const SpacerBox = styled.div`
  margin: 24px 0px;
`