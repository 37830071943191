import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow
} from "@mui/material";
import { destroy, fetchCampaigns } from "../../helpers/requests";
import { campaignPath } from "../../helpers/paths";
import { Campaign } from "../../helpers/types";
import { Delete as DeleteIcon } from '@mui/icons-material';
import { CampaignValue } from "./CampaignValue";
import dayjs from "dayjs";

export const Campaigns = () => {
  const [data, setData] = useState<Campaign[]>()

  const getData = async () => await fetchCampaigns();

 useEffect(() => {
    getData().then(setData)
  }, [])

  const handleDelete = async (campaignId) => {
    const confirmation = confirm("Are you sure?");
    
    if(confirmation) {
      await destroy(campaignPath(campaignId));
      getData().then(setData)
    }
  }

  return <div>
    <h2>Campaigns</h2>
    <TableContainer component={Paper}>
      <Table sx={{ minWidth: 650 }}>
        <TableHead>
          <TableRow>
            <TableCell>Campaign Name</TableCell>
            <TableCell align="right">ID</TableCell>
            <TableCell align="right">Value</TableCell>
            <TableCell align="right">Starts At</TableCell>
            <TableCell align="right">Ends At</TableCell>
            <TableCell align="right"># of Coupons</TableCell>
            <TableCell align="right">Actions</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
        {data && data.length > 0 && data.map(campaign =>
          <TableRow key={campaign.id}>
            <TableCell component="th" scope="row">
              <Link to={`/campaigns/${campaign.id}`}>{campaign.name}</Link>
            </TableCell>
            <TableCell align="right">{campaign.id}</TableCell>
            <TableCell align="right">
              <CampaignValue campaign={campaign} />
            </TableCell>
            <TableCell align="right">{campaign.starts_at ? dayjs(campaign.starts_at).format() : "-"}</TableCell>
            <TableCell align="right">{campaign.ends_at ? dayjs(campaign.ends_at).format() : "-"}</TableCell>
            <TableCell align="right">
              {campaign.code ? (
                <div>Unlimited, Code: {campaign.code}</div>
              ) : (
                <Link to={`/campaigns/${campaign.id}/coupons`}>Coupons</Link>
              )}
            </TableCell>
            <TableCell align="right">
              <DeleteIcon style={{ cursor: "pointer "}} onClick={() => handleDelete(campaign.id)} />
            </TableCell>
          </TableRow>
        )}
        { (!data || data.length === 0) &&
          <TableRow>
            <TableCell component="th" scope="row">
              No Campaigns exist. <Link to="/campaigns/new">Create one now.</Link>
            </TableCell>
          </TableRow>
        }
        </TableBody>
      </Table>
    </TableContainer>
  </div>
}