import React, { FC, useState } from "react";
import { redeemCoupon, validateCoupon } from "../../helpers/requests";
import { Button, TextField } from "@mui/material";

export const Tools: FC = () => {
  const [validateCodeValue, setValidateCodeValue] = useState<string>("");
  const [redeemCodeValue, setRedeemCodeValue] = useState<string>("");
  const [isValid, setIsValid] = useState<boolean | null>(null);
  const [isRedeemed, setIsRedeemed] = useState<boolean | null>(null);
  const [redeemError, setRedeemError] = useState<string | null>(null);

  const validateCode = async () => {
    const { valid } = await validateCoupon(validateCodeValue);
    setIsValid(valid);
  }

  const redeemCode = async () => {
    const { redeemed, error } = await redeemCoupon(redeemCodeValue);
    setIsRedeemed(redeemed);
    if (!redeemed) {
      setRedeemError(error)
    }
  }

  return <div>
    <h2>Validate Coupon</h2>
    <TextField value={validateCodeValue} onChange={e => setValidateCodeValue(e.target.value)} />
    <Button onClick={validateCode}>Validate</Button>
    {(isValid === true || isValid === false) && (isValid ? <p>VALID!</p> : <p>Invalid</p>)}

    <h2>Redeem Coupon</h2>
    <TextField value={redeemCodeValue} onChange={e => setRedeemCodeValue(e.target.value)} />
    <Button onClick={redeemCode}>Redeem</Button>
    {(isRedeemed === true || isRedeemed === false) && (isRedeemed ? <p>REDEEMED!</p> : <p>{redeemError || "Unable to redeem."}</p>)}
  </div>
}