import { Button, Typography } from "@mui/material";
import React, { FC } from "react";
import { SpacerBox, SplashPageContainer } from "../../styles/components";
import { apiDocsPath } from "../../helpers/paths";

export const About: FC<{ demoAccessToken: string }> = ({ demoAccessToken }) => {
  return <SplashPageContainer>
    <Typography variant="h1" textAlign="center">Coupon API</Typography>
    <Typography variant="h2" textAlign="center">Coupons & Promo Codes, Simplified.</Typography>
    <SpacerBox>
      <Typography variant="h5">Coupon API is the fastest, easiest, and most cost effective way to create and manage coupons and promo codes on the web.</Typography>
    </SpacerBox>
    <SpacerBox>
      <Button variant="outlined" onClick={() => location.href = apiDocsPath}>Get Started</Button>
    </SpacerBox>
      <Typography>Ready to try it out? User this Demo Access Token to connect to our API and start making requests NOW: <code>{demoAccessToken}</code> </Typography>
    <SpacerBox>
      <Typography variant="h4">How to Use Coupon API</Typography>
    </SpacerBox>
    <SpacerBox>
      <Typography>Create Campaigns and Coupons/Promo Codes through an intuitive Web UI, or via our REST API (GraphQL coming soon!).</Typography>
    </SpacerBox>
    <SpacerBox>
      <Typography variant="h4">Why Coupon API?</Typography>
    </SpacerBox>
    <SpacerBox>
      <Typography variant="h5">Simple</Typography>
      <Typography>Include an Access Token with your request and you are ready to start creating Coupons and Promo Codes.</Typography>
    </SpacerBox>
    <SpacerBox>
      <Typography variant="h5">Affordable</Typography>
      <Typography>Pay as you go pricing for affordability regardless of the size of your project.</Typography>
    </SpacerBox>
    <SpacerBox>
      <Typography variant="h5">Reliable</Typography>
      <Typography>A small team of developers committed to constantly improving and providing the best customer service possible.</Typography>
    </SpacerBox>
    <SpacerBox>
      <Typography variant="h4">Ready to Start?</Typography>
      <Typography>We are releasing Coupon API for Alpha testing in Q3 of 2024. Please reach out if you are interested in the product and would like information about joining the Alpha test!</Typography>
    </SpacerBox>
      <SpacerBox>
        <Button
          rel="noopener noreferrer"
          href="mailto:hello.coupon.api@gmail.com"
        >
          <Typography variant="button">
              Contact Us at hello.coupon.api@gmail.com
          </Typography>
        </Button>
      </SpacerBox>
  </SplashPageContainer>
}