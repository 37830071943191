import {
  organizationAccessTokensPath,
  campaignCouponsPath,
  campaignPath,
  campaignsPath,
  couponsPath,
  organizationPath,
  signOutPath,
  validateCouponPath,
  generateCampaignCouponsPath,
  redeemCouponPath
} from "./paths";
import { AccessToken, Campaign, Coupon, Organization } from "./types";

const BASE_URL = ""

const getCsrfToken = () => {
  return document.getElementById("app")?.dataset.csrfToken;
}

const getAccessToken = () => {
  return document.getElementById("app")?.dataset.accessToken;
}

export const fetchCampaigns = (): Promise<Campaign[]> => {
  return get(campaignsPath);
}

export const fetchCampaign = (campaignId: string): Promise<Campaign> => {
  return get(campaignPath(campaignId));
}

export const fetchCoupons = (): Promise<Coupon[]> => {
  return get(couponsPath);
}

export const fetchCampaignCoupons = (campaignId: string): Promise<Coupon[]> => {
  return get(campaignCouponsPath(campaignId));
}

export const fetchOrganization = (): Promise<Organization> => {
  return get(organizationPath);
}

export const deleteUserSession = (): Promise<Response> => {
  return destroy(signOutPath);
}

export const fetchAccessToken = (): Promise<AccessToken> => {
  return get(organizationAccessTokensPath);
}

export const createAccessToken  = (): Promise<AccessToken> => {
  return post(organizationAccessTokensPath);
}

export const validateCoupon = (code: string): Promise<{ valid: boolean }> => {
  return post(validateCouponPath(code))
}

export const redeemCoupon = (code: string): Promise<{ redeemed: boolean, error: string }> => {
  return post(redeemCouponPath(code))
}

export const generateCoupons = (campaignId: string, numCoupons: number, code?: string): Promise<{ valid: boolean }> => {
  return post(generateCampaignCouponsPath(campaignId, numCoupons, code))
}

export const get = (path) => {
  return fetch(`${BASE_URL}${path}`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      "X-Access-Token": getAccessToken() as string
    },
  }).then((res) => res.json())
}

export const post = (path, body = {}) => {
  const payload = {
    authenticity_token: getCsrfToken(),
    ...body
  }

  return fetch(`${BASE_URL}${path}`, {
    headers: {
      "Content-Type": "application/json",
      "X-Access-Token": getAccessToken() as string
    },
    method: "POST",
    body: JSON.stringify(payload)
  }).then((res) => res).then(res => res.json())
}

export const destroy = (path) => {
  return fetch(`${BASE_URL}${path}`, {
    headers: {
      "Content-Type": "application/json",
      "X-Access-Token": getAccessToken() as string
    },
    method: "DELETE",
    body: JSON.stringify({
      authenticity_token: getCsrfToken()
    })
  }).then((res) => res)
}