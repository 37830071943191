import React from "react";
import { createRoot } from 'react-dom/client';

import { App } from  "./components/app/App.tsx";
import { Splash } from  "./components/splash/Splash.tsx";

const appElement: HTMLElement | null = document.getElementById('app');
const splashElement: HTMLElement | null = document.getElementById('splash');

if (appElement) {
  const root = createRoot(appElement);
  root.render(<App />);
}

if (splashElement) {
  const root = createRoot(splashElement);
  root.render(<Splash />);
}